<template>
  <v-form @submit.prevent="submitResponse">
    <h2 v-if="task.content.config.heading" class="mb-4">
      <stemble-latex :content="task.content.config.heading" style="white-space: pre-wrap" />
    </h2>
    <p class="body-1 mb-4 mt-4">
      <stemble-latex :content="task.content.config.question" style="white-space: pre-wrap" />
    </p>
    <s-textarea
      v-model="inputs.answer"
      outlined
      :counter="task.content.config.characterLimit > 0"
      :maxlength="
        task.content.config.characterLimit > 0 ? task.content.config.characterLimit : undefined
      "
    />
    <div v-if="task.content.config.allowFileUpload" class="mb-4">
      <p>Any images/files can be attached below.</p>
      <p><b>Max file size</b>: 50 MB</p>
      <v-file-input v-model="attachments" multiple />
      <p class="mb-1" style="font-weight: bold">
        Have multiple files to upload? Click
        <a href="https://support.stemble.ca/attach-multiple-files ">here</a> to find out how.
      </p>
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'AiSpecificationQuestion',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answer: '',
      },
      attachments: [],
    };
  },
};
</script>
